<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V13C0 13.5523 0.447715 14 1 14C1.55228 14 2 13.5523 2 13V1ZM9.70711 3.70711C10.0976 3.31658 10.0976 2.68342 9.70711 2.29289C9.31658 1.90237 8.68342 1.90237 8.29289 2.29289L4.29289 6.29289C3.90237 6.68342 3.90237 7.31658 4.29289 7.70711L8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071C10.0976 11.3166 10.0976 10.6834 9.70711 10.2929L7.41421 8H17C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6H7.41421L9.70711 3.70711Z"
      fill="#5477A9"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlaybackLeftIcon'
}
</script>
