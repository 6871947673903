import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      height: 0,
      sizeScreen: 'thirdScreen',
      reportGroup: {},
      template: 1,
      reportIdValue: null
    }
  },
  props: ['paginatedReport', 'tableType', 'uniqReportId'],
  created() {
    this.getHeight()
  },
  computed: {
    ...mapState('reports', {
      reportIdToSwap: state => state.reportIdToSwap
    }),
    thisUnit() {
      return this.paginatedReport.units ? this.$store.getters['units/unit'](this.paginatedReport.units[0].id) : ''
    },
    thisGroupUnit() {
      return this.paginatedReport.groups ? this.$store.getters['units/unitGroup'](this.paginatedReport.groups[0].id) : ''
    },

    reportId() {
      if (this.reportIdValue !== null) {
        return this.reportIdValue
      }
      if (this.tableType === 'finance') {
        return 'finance'
      }
      if (this.tableType === 'chart') {
        const { id } = this.paginatedReport.template_tables[0]
        return id
      }
      const unitOrGroupId = this.paginatedReport.units ? this.paginatedReport.units[0].id : this.paginatedReport.groups[0].id
      return this.uniqReportId ? this.uniqReportId : this.paginatedReport.template_tables[0].id + unitOrGroupId + this.paginatedReport.from + this.paginatedReport.to
    },
    ...mapGetters('reports', ['report', 'tabledata', 'columndata', 'pssblCols', 'chartTable']),
    ...mapGetters('units', ['unit']),
    tableData() {
      const tableData = this.report(this.reportId)
      return this.report(this.reportId)
    },
    possibleColumns() {
      return this.pssblCols(this.reportId)
    },
    rowTableData() {
      const rowsData = this.tabledata(this.reportId)

      if (this.tableData?.report?.time_group) {
        const newRowsData = []
        const rowsDataLen = rowsData.length
        let iter = 0 // AV TMP - переписать получше
        rowsData.forEach(row => {
          let groupsCol = '-'
          if (rowsDataLen - 1 > iter) {
            groupsCol = this.tableData.report.time_group
          }
          newRowsData.push({
            ...row,
            'groups-col': groupsCol
          })
          iter++
        })
        return newRowsData
      }

      return rowsData
    },
    columnTableData() {
      return this.columndata(this.reportId)
    }
  },
  watch: {
    reportIdToSwap(val) {
      this.reportIdValue = val
    }
  },
  methods: {
    editUnit() {
      if (this.thisUnit) {
        this.$parent.editUnit(this.thisUnit)
      } else if (this.thisGroupUnit) {
        this.$parent.editGroupUnit(this.thisGroupUnit)
      }
    },
    getHeight(sizeScreen = 'thirdScreen', isBarShown = false) {
      this.isPopoverVisible = false
      if (sizeScreen === 'thirdScreen') {
        if (isBarShown) {
          this.height = window.innerHeight / 3 - 7.5 + 120
        } else {
          this.height = window.innerHeight / 3 - 7.5
        }
      } else if (sizeScreen === 'medScreen') {
        if (isBarShown) {
          this.height = window.innerHeight / 2 - 5 + 120
        } else {
          this.height = window.innerHeight / 2 - 5
        }
      } else if (sizeScreen === 'maxScreen') {
        this.height = window.innerHeight - 10
      } else if (sizeScreen === 'barChart') {
        this.height += 120
      } else if (isBarShown) {
        this.height = window.innerHeight / 4 + 120
      } else {
        this.height = window.innerHeight / 4
      }
      this.updateChartAfterChangeHight = !this.updateChartAfterChangeHight
      if (this.tableType === 'finance') {
        this.height = window.innerHeight - 10

        this.$emit('setSize', this.height, this.reportId)
      }
      this.$emit('setSize', this.height, this.reportId)
    },
    onDragged({ el, deltaX, deltaY, first, last }) {
      el = el.parentElement
      if (first) {
        const ids = Object.keys(this.$parent.reportStyles).filter(id => id !== this.reportId)

        if (this.$parent.zIndex && this.$parent.reportStyles[this.reportId]['z-index']) {
          this.$parent.zIndex = ids.length === 2 ? 23 : ids.length === 1 ? 22 : 21
          this.$parent.reportStyles[this.reportId]['z-index'] = this.$parent.zIndex
          ids.forEach(id => {
            if (this.$parent.reportStyles[this.reportId]['z-index'] <= this.$parent.reportStyles[id]['z-index']) {
              this.$parent.reportStyles[id]['z-index']--
            }
          })
          return
        }
      }

      if (last) return

      const l = +window.getComputedStyle(el).left.slice(0, -2) || 0
      const b = +window.getComputedStyle(el).bottom.slice(0, -2) || 0
      // r = +window.getComputedStyle(el)['right'].slice(0, -2) || 0
      let left
      let bottom

      left = `${l + deltaX}px`
      bottom = `${b + -deltaY}px`
      // console.log('right',left)
      el.style.left = left
      el.style.bottom = bottom
      if (b > 0 && l > 0) {
        bottom = `${b + -deltaY}px`
        left = `${l + deltaX}px`
      } else if (b < -250 && l > 0) {
        bottom = '-250px'
        left = `${l + deltaX}px`
      } else if (b > -250 && l < 0) {
        bottom = `${b + -deltaY}px`
        left = '0px'
      } else if (l < 0 && b < -250) {
        left = '0px'
        bottom = '-250px'
      }

      this.$emit('change-position', {
        id: this.reportId,
        left,
        bottom
      })
      // this.$parent.reportStyles[
      //   this.paginatedReport.template_tables[0].id
      // ].bottom = bottom
      // this.$parent.reportStyles[
      //   this.paginatedReport.template_tables[0].id
      // ].left = left
    },

    close() {
      this.$emit('close', this.paginatedReport ? this.paginatedReport : { reportId: 'finance' })
    }
  }
}
