<template>
  <svg
    class="transition-transform ease-in-out duration-500 ml-3"
    width="24"
    height="24"
    fill="none"
    :style="scale && { transform: 'scale(1, -1)' }"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="transition-colors ease-in-out duration-200"
      d="M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z"
      :fill="color"
    />
    <path
      class="transition-colors ease-in-out duration-200"
      d="M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'SortUpIcon',
  props: {
    color: {
      type: String,
      default: '#91B4E7'
    },
    scale: {
      type: Boolean,
      default: false
    }
  }
}
</script>
