<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999021 12.9932L0.999021 0.993161L10.749 6.99316L0.999021 12.9932Z"
      stroke="#5477A9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.999 12.9932L13.999 0.993161"
      stroke="#5477A9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlaybackRightIcon'
}
</script>
