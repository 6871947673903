<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 9C18 13.9706 13.9706 18 9 18C4.02942 18 0 13.9706 0 9C0 4.02943 4.02942 0 9 0C13.9706 0 18 4.02943 18 9ZM15.9291 10C15.4906 13.0657 13.0657 15.4906 10 15.9291V14C10 13.4477 9.55229 13 9 13C8.44771 13 8 13.4477 8 14V15.9291C4.93433 15.4906 2.5094 13.0657 2.07089 10H4C4.55228 10 5 9.55229 5 9C5 8.44771 4.55228 8 4 8H2.07089C2.5094 4.93431 4.93433 2.5094 8 2.07089V4C8 4.55228 8.44771 5 9 5C9.55229 5 10 4.55228 10 4V2.07089C13.0657 2.5094 15.4906 4.93431 15.9291 8H14C13.4477 8 13 8.44771 13 9C13 9.55229 13.4477 10 14 10H15.9291Z"
      fill="#5477A9"
    />
  </svg>
</template>

<script>
export default {
  name: 'TargetIcon'
}
</script>
