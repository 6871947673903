<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6 9.8a7.8 7.8 0 10-7.8 7.8 7.819 7.819 0 005.076-1.872l.324.324V17l6 6 1.8-1.8-6-6h-.948l-.324-.324A7.819 7.819 0 0017.6 9.8zm-13.201 0c0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4z"
      fill="#CFDBEB"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>
