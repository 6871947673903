<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'

const { useGetters } = createVuexHelpers()

const { getVuexReportTableLoader } = useGetters('reports', ['getVuexReportTableLoader'])

const emits = defineEmits(['show-row-on-map', 'show-details-report'])
const props = defineProps({
  columnTable: {
    type: Array,
    default: () => []
  },
  reportRows: {
    type: Array,
    default: () => []
  },
  reportUniqId: {
    type: String,
    default: ''
  }
})

const selectedRow = ref(null)
const activeParentId = ref(null)

const showRowOnMap = (data, index) => {
  selectedRow.value = data.uniqId
  activeParentId.value = data.parentId
  emits('show-row-on-map', data, index)
}

const showDetailsReport = (row, index) => {
  emits('show-details-report', { row, index, uniqReportTableId: props.reportUniqId })
}

const spaceMargin = cell => {
  if (cell.parentId) {
    const level = cell.parentId.split('.').length - 1
    const SPACING = 10

    return { 'margin-left': `${level * SPACING}px` }
  }

  return { 'margin-left': '30px' }
}

const getRowClass = parentId => {
  const level = parentId.split('.').length - 1
  switch (level) {
    case 0:
      return 'report-row--level-0'
    case 1:
      return 'report-row--level-1'
    case 2:
      return 'report-row--level-2'
    case 3:
      return 'report-row--level-3'
    default:
      return ''
  }
}

const getDisplayValue = (cell, key) => {
    const value = cell[key];
    return value !== undefined && value !== null ? value : '-';
  }
</script>

<template>
  <tbody>
    <tr v-for="(cell, index) in props.reportRows" :class="['report-row', 'cursor-pointer', 'hover_bg-hoverrow', { 'report-row--selected': selectedRow === cell.uniqId }, getRowClass(cell.parentId)]" :key="index" @click="showRowOnMap(cell, index)">
      <td v-for="(column, idx) in columnTable" :class="[{ 'report-row--last-row': index === props.reportRows.length - 1 }]" class="report-td relative px-4 font-semibold whitespace-no-wrap table-text" :key="idx">
        <button v-if="column.key === '№' && cell._can_be_detailed" :style="spaceMargin(cell)" class="btn-more" @click.stop="showDetailsReport(cell, index)">
          {{ Number(cell.isCollapsed) ? '-' : '+' }}
        </button>
        <span :style="spaceMargin(cell)"> {{ getDisplayValue(cell, column.key) }}</span>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
.isRowSelected {
  background: #eef5ff;
}
.btn-more {
  position: absolute;
  width: 14px;
  height: 12px;
  border-radius: 2px;
  left: 1px;
  top: 2px;
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  outline: none;
  &:hover {
    background: #fff;
  }
}

.report-row {
  position: relative;
  &--last-row {
    font-weight: 700;
  }

  &--same-parent {
    background-color: #ccc;
  }

  &--level-0 {
    background-color: #fff;
  }

  &--level-1 {
    background-color: rgba(231, 235, 243, 0.25);
  }

  &--level-2 {
    background-color: #eef0f3;
  }

  &--level-3 {
    background-color: #e2e8f0;
  }

  &--selected {
    background-color: #eef5ff;
  }
}

.report-td {
  margin-left: 10px;
}

.trTable tr > td {
  line-height: 14px;
}

.report-wrapper-row-td {
  display: flex;
  &:hover {
    background: #d1e6ff;
  }
}

.report-child {
  &__loading {
    position: absolute;
    width: 100%;
    padding-left: 20px;
    font-size: 12px;
  }
}
</style>
