<template>
  <svg
    class="cursor-pointer"
    width="25"
    height="25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 6H5.75a.738.738 0 00-.53.228.793.793 0 00-.22.55v12.444c0 .206.08.404.22.55.14.146.332.228.53.228h13.5c.198 0 .39-.082.53-.228a.793.793 0 00.22-.55V6.778a.793.793 0 00-.22-.55.738.738 0 00-.53-.228zM19 7.037V9.37L6 9.354V7.037h13zM6 18.963v-8.572l13 .016v8.556H6z"
      fill="#5477A9"
    />
  </svg>
</template>

<script>
export default {
  name: 'WindowIcon'
}
</script>
