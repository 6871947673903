<script setup>
import { defineProps, defineEmits } from 'vue'
import PlaybackIcon from '@/components/reports/icons/PlaybackIcon.vue'
import PlaybackLeftIcon from '@/components/reports/icons/PlaybackLeftIcon.vue'
import PlaybackRightIcon from '@/components/reports/icons/PlaybackRightIcon.vue'
import CloseTrackIcon from '@/components/reports/icons/CloseTrackIcon.vue'
import TargetIcon from '@/components/reports/icons/TargetIcon.vue'
import StopIcon from '@/components/reports/icons/StopIcon.vue'
import PlayIcon from '@/components/reports/icons/PlayIcon.vue'

const props = defineProps({
  isMovingBack: {
    type: Boolean,
    default: false
  },
  isPlayMarker: {
    type: Boolean,
    default: false
  },
  speed: {
    type: Number
  }
})

const markerSpeeds = [
  {
    value: 10,
    label: '10x'
  },
  {
    value: 20,
    label: '20x'
  },
  {
    value: 50,
    label: '50x'
  },
  {
    value: 100,
    label: '100x'
  },
  {
    value: 300,
    label: '300x'
  }
]

const emit = defineEmits(['change-speed'])

const changeSpeed = item => {
  emit('change-speed', item)
}
</script>

<template>
  <div class="flex-1 flex justify-center items-center">
    <button class="focus_outline-none p-0 mx-4" type="button" :disabled="props.isMovingBack" @click="$emit('go-to-start')">
      <PlaybackIcon />
    </button>
    <button class="focus_outline-none p-0 mx-4" type="button" :disabled="props.isMovingBack" @click="$emit('move-back')">
      <PlaybackLeftIcon />
    </button>
    <button v-if="props.isPlayMarker" class="w-3 focus_outline-none p-0 mx-4" type="button" :disabled="props.isMovingBack" @click="$emit('marker-play')">
      <PlayIcon />
    </button>
    <button v-else class="w-3 focus_outline-none p-0 mx-4" type="button" @click="$emit('marker-pause')">
      <StopIcon />
    </button>
    <button class="focus_outline-none p-0 mx-4" type="button" @click="$emit('focus-track')">
      <TargetIcon />
    </button>
    <button class="focus_outline-none p-0 mx-4" type="button" :disabled="props.isMovingBack" @click="$emit('go-to-end')">
      <PlaybackRightIcon />
    </button>
    <el-select class="w-20" :value="props.speed" @change="changeSpeed">
      <el-option v-for="item in markerSpeeds" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <div class="focus_outline-none p-0 mx-4 cursor-pointer" type="button" @click="$emit('close-track')">
      <CloseTrackIcon />
    </div>
  </div>
</template>
