import moment from 'moment'
import L from 'leaflet'
const ZOOM = 14
/**
 * Возвращает строку даты
 * @param {String} dateFormat - формат даты
 * @param {String} timeFormat - формат времени
 * @returns {String}
 */
export const last24hours = (dateFormat, timeFormat) => {
  const from = moment().startOf('day')
  const to = moment().add(1, 'days').startOf('day')
  return `${from.format(`${dateFormat} ${timeFormat}`)} - ${to.format(`${dateFormat} ${timeFormat}`)}`
}

/**
 * Возвращает обьект для запроса
 * @param {Object} paginatedReport - Данные отчета
 * @returns {Object}
 */
export const getDataSwitchObject = paginatedReport => {
  return {
    zoom: ZOOM,
    units: paginatedReport.units,
    from: paginatedReport.from,
    to: paginatedReport.to
  }
}

/**
 * Возвращает обьект полигон для карты
 * @param {Object} geozone - Обьект с данными геозоны
 * @returns {Object}
 */
export const getPolygon = geozone => {
  return L.polygon(geozone.geometry, {
    color: 'red',
    opacity: 0.3,
    type: geozone.type.key
  }).bindTooltip(geozone.name, {
    pane: 'geozonenamePane',
    permanent: true,
    className: 'geozone-label-red',
    direction: 'bottom'
  })
}

/**
 * Возвращает обьект круг для карты
 * @param {Object} geozone - Обьект с данными геозоны
 * @returns {Object}
 */
export const getCircle = geozone => {
  return L.circle(geozone.geometry[0], {
    radius: geozone.width,
    color: 'red',
    weight: 1,
    type: geozone.type.key
  }).bindTooltip(geozone.name, {
    pane: 'geozonenamePane',
    permanent: true,
    className: 'geozone-label-red',
    direction: 'bottom'
  })
}

/**
 * Возвращает обьект полилайн для карты
 * @param {Object} geozone - Обьект с данными геозоны
 * @returns {Object}
 */
export const getPolyline = geozone => {
  return L.polyline(geozone.geometry, {
    color: 'red',
    weight: 1,
    type: geozone.type.key
  }).bindTooltip(geozone.name, {
    pane: 'geozonenamePane',
    permanent: true,
    className: 'geozone-label-red',
    direction: 'bottom'
  })
}
