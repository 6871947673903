<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="14" height="14" rx="2" fill="#5477A9" />
  </svg>
</template>

<script>
export default {
  name: 'StopIcon'
}
</script>
