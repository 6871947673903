export const allRoles = {
  NO_ACCESS: 'NO_ACCESS',         // 0
  OBSERVER: 'OBSERVER',           // 1
  READER: 'READER',               // 2
  OPERATOR: 'OPERATOR',           // 3
  EDITOR: 'EDITOR',               // 4
  ADMIN: 'ADMIN',                 // 5
  SUPERVISOR: 'SUPERVISOR',       // 6
  TARIFF_EDITOR: 'TARIFF_EDITOR', // 7
  CONTROLLER: 'CONTROLLER',       // 8
}

// 0
export const noAccessRoleAccess = [
  allRoles.NO_ACCESS,
]
// 1
export const observerRoleAccess = [
  ...noAccessRoleAccess,
  allRoles.OBSERVER,
]
// 2
export const readerRoleAccess = [
  ...observerRoleAccess,
  allRoles.READER,
]
// 3
export const operatorRoleAccess = [
  ...readerRoleAccess,
  allRoles.OPERATOR,
]
// 4
export const editorRoleAccess = [
  ...operatorRoleAccess,
  allRoles.EDITOR,
]
// 5
export const adminRoleAccess = [
  ...editorRoleAccess,
  allRoles.ADMIN,
]
// 6
export const supervisorRoleAccess = [
  ...adminRoleAccess,
  allRoles.SUPERVISOR,
]
// 7
export const tariffEditorRoleAccess = [
  ...supervisorRoleAccess,
  allRoles.TARIFF_EDITOR,
]
// 8
export const controllerRoleAccess = [
  ...tariffEditorRoleAccess,
  allRoles.CONTROLLER,
]

export const accessByRole = {
  [allRoles.NO_ACCESS]: noAccessRoleAccess,
  [allRoles.OBSERVER]: observerRoleAccess,
  [allRoles.READER]: readerRoleAccess,
  [allRoles.OPERATOR]: operatorRoleAccess,
  [allRoles.EDITOR]: editorRoleAccess,
  [allRoles.ADMIN]: adminRoleAccess,
  [allRoles.SUPERVISOR]: supervisorRoleAccess,
  [allRoles.TARIFF_EDITOR]: tariffEditorRoleAccess,
  [allRoles.CONTROLLER]: controllerRoleAccess,
}
