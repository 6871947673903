<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 6.14178C13.1667 6.52321 13.1667 7.47679 12.5 7.85822L2 13.8658C1.33333 14.2472 0.5 13.7704 0.5 13.0075V0.992464C0.5 0.229602 1.33333 -0.247185 2 0.134246L12.5 6.14178Z"
      fill="#5477A9"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlayIcon'
}
</script>
