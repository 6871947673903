<template>
  <svg
    class="cursor-pointer"
    width="25"
    height="25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 10.945c-.197 0-.392.04-.574.118a1.497 1.497 0 00-.487.337 1.604 1.604 0 000 2.199c.14.145.305.26.487.338a1.453 1.453 0 001.635-.338c.28-.291.439-.687.439-1.1 0-.412-.159-.807-.44-1.099a1.474 1.474 0 00-1.06-.455zm0-1.837c.197 0 .392-.04.574-.118.182-.078.347-.193.486-.337.14-.145.25-.316.325-.505a1.605 1.605 0 00-.326-1.693 1.496 1.496 0 00-.487-.337 1.453 1.453 0 00-1.633.338A1.583 1.583 0 0011 7.555c0 .412.158.807.44 1.099.28.291.662.455 1.06.454zm0 6.782c-.398 0-.78.164-1.06.456-.282.291-.44.687-.44 1.1 0 .412.158.807.44 1.099.28.291.662.455 1.06.455s.78-.164 1.06-.455c.282-.292.44-.688.44-1.1 0-.412-.158-.808-.44-1.1a1.474 1.474 0 00-1.06-.455z"
      fill="#5477A9"
    />
    <defs>
      <clippath id="clip0">
        <path fill="#fff" transform="translate(11 6)" d="M0 0h3v13H0z" />
      </clippath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'DotsIcon'
}
</script>
