<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  tableReportName: {
    type: String,
    default: ''
  },
  selectedObject: {
    type: String,
    default: ''
  },
  objectOrGroup: {
    type: Array,
    default: () => []
  },
  last24hours: {
    type: String,
    default: ''
  },
  dateFromDateTo: {
    type: String,
    default: ''
  },
  tableType: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:selectedObject, change'])

const switchObject = value => {
  emit('update:selectedObject', value)
  emit('change', value)
}
</script>

<template>
  <div class="flex flex-col text-darkblue font-SourceSansPro" style="align-self: center; height: 40px">
    <!-- Название отчета -->
    <div class="flex">
      <p class="truncate text-darkblue text-base font-bold" style="font-size: 13px">
        {{ props.tableReportName }}
      </p>
      <el-select :value="props.selectedObject" class="custom-select" style="width: 200px; height: 24px" filterable @change="switchObject">
        <el-option v-for="object in props.objectOrGroup" :key="object.id" :label="object.name" :value="object.id" />
      </el-select>
    </div>

    <!-- Название обьекта -->
    <div class="flex cursor-pointer items-center">
      <p class="report-date">
        {{ props.dateFromDateTo }}
      </p>
    </div>
  </div>
</template>
