<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function (tooltip) {
            if (!tooltip) return
            tooltip.displayColors = false
          },
          callbacks: {
            label: function (tooltipItem, data) {
              return 'X :' + tooltipItem.xLabel
            },
            title: function (tooltipItem, data) {
              return 'Y :' + tooltipItem[0].yLabel
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                callback: function (value) {
                  return value.substr(0, 10) //truncate
                }
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
